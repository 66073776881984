import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-name-full',
  templateUrl: './name-full.component.html',
  styleUrls: ['./name-full.component.scss']
})
export class NameFullComponent implements OnInit {

  constructor(
   public globals: Globals) { }

  first_name = '';
  last_name = '';
  first;
  last;
isdisabled=true;
  ngOnInit() {
    const modal = this.globals.getModalValue();
    if (undefined !== modal) {
      this.first_name = modal["first_name"];
      this.last_name = modal["last_name"];
      this.first = new FormControl(this.first_name, [
        Validators.required
      ]);
      this.last = new FormControl(this.last_name, [
        Validators.required
      ]);
      this.isdisabled=false;
    }else{
      this.first = new FormControl('', [
        Validators.required
      ]);
      this.last = new FormControl('', [
        Validators.required
      ]);
    }
  }

  setValue(first_name,last_name) {
    this.globals.setValue({last_name:last_name,first_name:first_name});
  }

  

}
