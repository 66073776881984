import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-loan-property-value',
  templateUrl: './loan-property-value.component.html',
  styleUrls: ['./loan-property-value.component.scss']
})
export class LoanPropertyValueComponent implements OnInit {

   constructor(public globals: Globals) { }

  section_modal = 200000;

  label="estimated property value?";

  loanamountestimate = new FormControl(200000, [
    Validators.required
  ]);

  ngOnInit() {
    console.log("i am in loan property value");
    const modal = this.globals.getModalValue();
    if (undefined !== modal) {
      this.section_modal = parseFloat(modal);      
    }

    if(this.globals.resultModal["loan-purpose"]== "Purchase" && this.globals.resultModal["under-contract"]== "No"){
      this.label="estimated purchase price?";
    }
  }

  setValue(value) {
    this.globals.setValue(value);
  }

}
