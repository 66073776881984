import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import * as _ from "underscore";
import { GlobalHttpService } from "./http.service";
import { HostListener } from "@angular/core";
import { Title } from "@angular/platform-browser";
import * as moment from "moment";
import { environment } from "../environments/environment";

@Injectable()
export class Globals {
  lead_id: string = '';
  agentId: String = environment.agentID;
//  agentId: String = '305305';
  source: string = window.location.origin;
  campaign: string = 'other';
  lead: any;
  isAllLoad = false;
  activePage = 0;
  activeSectionId: any = 0;
  resultModal: Object = {};
  pageComplete = 0;
  userGeoPull: any;
  isMobile: boolean;
  action: String = "";
  headerSaveBtnLabel = "Save your progress";
  location = {};

  preferences = {showBackgroundGraphic:false,custom_slide:"",showAvatar:false,showFooterLinks:false};

  constructor(
    private titleService: Title,
    private httpService: GlobalHttpService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private _router: Router
  ) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        try {
          this.titleService.setTitle(this.lead.preferences[0].title);
        } catch (err) {}

        this.activeSectionId = this.route.root.firstChild.snapshot.data[
          "state"
        ];
        console.log(this.activeSectionId);
        if(this.activeSectionId){
          this.cookieService.set("activeSession", this.activeSectionId);
        }
       

        let LeadModal = {
          lead_id: this.lead_id,
          agentId: this.agentId,
          lead_data: {
            status: "incomplete",
            currentlyActive: true,
            currentlyOn: this.activeSectionId
          }
        };

        try {
          LeadModal.lead_data["style"] = this.lead.preferences[0].style;
        } catch (err) {}

        try {
          LeadModal.lead_data["location"] = this.location;
        } catch (err) {}

        
      }

      window.scrollTo(0, 0);
    });
  }

  setValue(value) {
    for (let i = 0; i < this.lead.leadSections.length; i++) {
      if (this.lead.leadSections[i].sectionComponent === this.activeSectionId) {
        this.activePage = i;
      }
    }

    this.action = "next";
    this.resultModal[this.activeSectionId] = value;
    this.cookieService.set('access-token', JSON.stringify(this.resultModal));
    this.cookieService.set('pageComplete', this.pageComplete.toString());
    this.cookieService.set('lead_id', this.lead_id);

    const st = this;
    console.log('i am going to save step');

    try {
    } catch (err) {}

    let LeadModal = {
      lead_id: this.lead_id,
      agentId: this.agentId,
      lead_data: {
        status: 'incomplete',
        currentlyActive: true,
        currentlyOn: this.activeSectionId,
        sections: this.resultModal
      }
    };

    try {
      LeadModal.lead_data['style'] = this.lead.preferences[0].style;
    } catch (err) {}

    try {
      LeadModal.lead_data['location'] = this.location;
    } catch (err) {}

    console.log(LeadModal);
    if (this.activeSectionId == 'email' || this.activeSectionId == 'phone') {
      LeadModal.lead_data.status = 'converted';
    }

    const current_section = _.findWhere(st.lead.leadSections, {
      sectionComponent: st.activeSectionId
    });

    if (current_section['next_section'] == 'thank-you') {
      LeadModal.lead_data.status = 'submitted';
    }

    this.httpService.updateLeadData(LeadModal);
        try {
          st._router.navigate(['/' + this.getRedirect(current_section)]);
          st.pageComplete++;
          if (st.pageComplete != st.lead.leadSections.length - 1) {
            st.activePage++;
          }
        } catch (err) {
          st._router.navigate(['/' + this.getRedirect(current_section)]);
        }
        
      
  }

  getRedirect(current_section) {
    if (undefined != current_section.condition) {
      if (
        this.resultModal[current_section.condition.key] ==
        current_section.condition.value
      ) {
        return current_section.condition.redirect;
      }
    }

    if (current_section.is_dependent) {
      return current_section.redirect[
        this.resultModal[current_section.sectionComponent]
      ];
    } else {
      return current_section['next_section'];
    }
  }

 

  startOver() {
    this.lead_id = new Date().getTime() + '';

    let LeadModal = {
      lead_id: this.lead_id,
      agentId: this.agentId,
      lead_data: {
        source: this.source,
        campaign: this.campaign,
        status: 'not started',
        currentlyActive: true,
        currentlyOn: this.activeSectionId
      }
    };
    try {
      LeadModal.lead_data['style'] = this.lead.preferences[0].style;
    } catch (err) {}
    try {
      LeadModal.lead_data['location'] = this.location;
    } catch (err) {}

    
    this.cookieService.deleteAll();
    this.cookieService.deleteAll("/");
    this.isAllLoad=true;

    this._router.navigate(['/start']);
  }

  resume() {
    this.resultModal = JSON.parse(this.cookieService.get('access-token'));
    this.activeSectionId = this.cookieService.get('activeSession');
    this.lead_id == this.cookieService.get('lead_id');
    this.pageComplete = parseInt(this.cookieService.get('pageComplete'), 10);

    for (let i = 0; i < this.lead.leadSections.length; i++) {
      if (this.lead.leadSections[i].sectionComponent === this.activeSectionId) {
        this.activePage = i + 1;
      }
    }
    this._router.navigate(['/' + this.activeSectionId]);
  }

  getModalValue() {
    let value = '';
    value = this.resultModal[this.activeSectionId];
    console.log('i return modal value' + value);
    return value;
  }

  

  getEstimateValue() {
    if (this.resultModal['contract-payment'])
      return this.resultModal['contract-payment'];
    else if (this.resultModal['loan-property-value'])
      return this.resultModal['loan-property-value'];
    else 0;
  }


  getLoanType() {
    let type = '';
    if (this.resultModal['loan-purpose'])
      type = this.resultModal['loan-purpose'];

    if (type == 'Purchase') return 'purchasing';
    else if (type == 'Refinance') return 'refinancing';
    else type;
  }

  activateHandsetLayout() {
    this.headerSaveBtnLabel = 'Save';
    this.isMobile = true;
  }




  showChat() {
    window['ChatraSetup'] = {
      colors: {
        buttonText: '#f0f0f0',
        /* chat button text color */
        buttonBg: '#0b68ea' /* chat button background color */
      }
    };
    (function(d, w, c) {
      w['ChatraID'] = 'Pt9Yyj5BWWzN5A43d';
      var s = d.createElement('script');
      w[c] =
        w[c] ||
        function() {
          (w[c].q = w[c].q || []).push(arguments);
        };
      s.async = true;
      s.src = 'https://call.chatra.io/chatra.js';
      if (d.head) d.head.appendChild(s);
    })(document, window, 'Chatra');
  }
}


