import { Component, OnInit } from '@angular/core';
import { GlobalHttpService } from '../../http.service';
import { Globals } from 'src/app/global';
import { CookieService } from "ngx-cookie-service";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit {
  constructor(
    private httpService: GlobalHttpService,
    private cookieService: CookieService,
    public globals: Globals
  ) {

    this.estimated_valueChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe(value => {
      this.estimated_value = value;
      this.setEstimate(this.estimated_value, this.section_modal_percentage)
    });

    this.downpaymentChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe(value => {
      this.downpayment = value;
      this.setPercentage(this.downpayment,'value');
    });

    this.percentageChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe(value => {
      this.section_modal_percentage = value;
      this.setPercentage(this.section_modal_percentage,'percentage');
    });
  }
  isLoader = true;

  isAjaxLoader = true;
  rates: any = [];

  estimated_value = 0;
  estimated_valueChanged: Subject<number> = new Subject();

  estimatedValueChanged(event) {
    this.estimated_valueChanged.next(event);
  }

  downpayment = 0;
  downpaymentChanged: Subject<number> = new Subject();

  downpaymentValueChanged(event) {
    this.downpaymentChanged.next(event);
  }
  
  section_modal_percentage = 0;
  percentageChanged: Subject<number> = new Subject();

  percentageValueChanged(event) {
    this.percentageChanged.next(event);
  }


  selectedCredit = 'Good';

  old_downpayment = 0;
  old_section_modal_percentage = 0;
  old_selectedCredit = 'Good';

  ngOnInit() {
    this.getRates();
    this.isLoader = true;
    console.log('i am getting rates');
    const modal = this.globals.getEstimateValue();
    if (undefined !== modal) {
      this.estimated_value = this.globals.getEstimateValue();
      this.downpayment = parseFloat(this.globals.resultModal['down-payment']);
      this.selectedCredit = this.globals.resultModal['credit'];

      this.old_downpayment = this.downpayment;
      this.old_selectedCredit = this.selectedCredit;
      this.setPercentage(this.downpayment, 'value');
      this.old_section_modal_percentage = this.section_modal_percentage;
    } else {
      this.estimated_value = this.globals.getEstimateValue();
      this.setPercentage(this.section_modal_percentage, 'percentage');
    }
  }

  getRates() {
    console.log('cts');
    this.httpService.getRates().subscribe(
      data => {
        this.rates = data['message'];
      },
      err => {
        console.log(err);
      },
      () => {
        console.log('Step saved');
        this.isLoader = false;
        this.isAjaxLoader = false;
      }
    );
  }

  setValueForRates() {

    if (this.estimated_value && this.estimated_value > 0 && this.downpayment && this.downpayment > 0 && (this.old_downpayment != this.downpayment || this.old_section_modal_percentage != this.section_modal_percentage || this.old_selectedCredit != this.selectedCredit)) {
      if (this.globals.resultModal['contract-payment']) {
        this.globals.resultModal['contract-payment'] = this.estimated_value;
      } else if (this.globals.resultModal['loan-property-value']) {
        this.globals.resultModal['loan-property-value'] = this.estimated_value;
      }

      this.old_downpayment = this.downpayment
      this.old_section_modal_percentage = this.section_modal_percentage
      this.old_selectedCredit = this.selectedCredit


      this.globals.resultModal['down-payment'] = this.downpayment;

      const st = this;
      console.log('i am going to save step');

      this.cookieService.set('access-token', JSON.stringify(this.globals.resultModal));

      const LeadModal = {
        lead_id: this.globals.lead_id,
        agentId: this.globals.agentId,
        lead_data: {
          status: 'incomplete',
          currentlyActive: true,
          currentlyOn: this.globals.activeSectionId,
          sections: this.globals.resultModal
        }
      };
      this.isAjaxLoader = true;
      this.httpService.updateLeadData(LeadModal);
      this.getRates();
    }
  }

  setValue() {
    this.globals.setValue('');
  }

  setPercentage(val, type) {
    console.log(val, type);
    if (type === 'percentage') {
      this.downpayment = this.estimated_value * (val / 100);
    } else {
      this.section_modal_percentage = (val / this.estimated_value) * 100;
    }
  }

  setEstimate(val, per) {

    this.downpayment = val * (per / 100);
    console.log("rate set");

  }

  loanamountestimate = new FormControl('', [Validators.required]);

  loanamountestimate_percentage = new FormControl('', [Validators.required]);

  downpayment_d = new FormControl('', [Validators.required]);

}
