import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormGroup } from '@angular/forms/src/model';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  form: FormGroup;




    constructor(
      private dialogRef: MatDialogRef<SignupComponent>,
      @Inject(MAT_DIALOG_DATA) data) {

  }
      ngOnInit() {

  }
  onCloseConfirm() {
    this.dialogRef.close();

  }
  onCloseCancel() {
    this.dialogRef.close();

  }

}
