import { Component, OnInit } from '@angular/core';
import { fadeAnimation } from '../general/animations';
import { GlobalHttpService } from '../http.service';
import { Globals } from '../global';
import { HostBinding,HostListener } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AlreadyFilledComponent } from 'src/app/general/already-filled/already-filled.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  isSplash=false;
  constructor(
    public local: LocalStorageService, 
    private httpService: GlobalHttpService,
    public globals: Globals,
    private _router: Router,
    private cookieService: CookieService,
    public dialog: MatDialog,
    breakpointObserver: BreakpointObserver
  ) {
   }

  ngOnInit() {

if(this.globals.lead && this.globals.lead.preferences && this.globals.lead.preferences.length && this.globals.lead.preferences[0].showSplash){
      let el=this;
      el.isSplash=true;
      setTimeout(function(){
        el._router.navigate(['/' + el.globals.lead.leadSections[0].sectionComponent]);
        el.isSplash=false;
      },4000);
    }else{
      if(this.globals.lead && this.globals.lead.leadSections && this.globals.lead.leadSections.length){
        this._router.navigate(['/' + this.globals.lead.leadSections[0].sectionComponent]);
      }
      
    }
  

  }


}
