import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {
  state = "";
  constructor(private route: ActivatedRoute, public globals: Globals, private router: Router) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.state = this.route.root.firstChild.snapshot.data['state'];
        console.log(this.state);
      }
    });
  }

  section_modal = '';

  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  ngOnInit() {
    let modal = this.globals.getModalValue();
    if (undefined !== modal) {
      modal = modal.replace("+1", "");
      this.section_modal = modal;
    }
  }

  setValue(value) {
    const new_val = value.match(/\d/g).join("");
    this.globals.setValue("+1" + new_val);
  }

  phone = new FormControl('', [
    Validators.required, Validators.minLength(10),
  ]);

  isPhoneNumber(val) {
    let n = val.match(/\d/g)
    if (n && n != null) {
      n = n.join("");
    } else {
      n = "";
    }
    return n.length;
  }

}
