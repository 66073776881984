import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
const expiry_date = moment().add(3, 'days').toString();
@Injectable()
export class GlobalHttpService {

    url = environment.url;
    vis: any;

    constructor(private cookieService: CookieService, private http: HttpClient, private route: Router) {

    }

    // Uses http.get() to load data from a single API endpoint
    getLead(domain:string) {
        console.log('===>domain', domain);
       
        return this.http.get(this.url + '/lynx/get-lead?domain=' + domain);
    }

    

    searchCities(term) {
        return this.http.get(this.url + '/lynx/get-places?query=' + term);
    }

    searchAddress(term) {
        return this.http.get(this.url + '/lynx/get-address?query=' + term);
    }

    getUserIp() {
        return this.http.get('https://lynx-freegeoip.herokuapp.com/json/');

    }

    updateLeadData(data): Observable<any>{
        if (data.lead_data && data.lead_data.sections) {
            if (this.cookieService.get('LeadId')) {
                const sessionKey = this.cookieService.get('sessionKey');
                this.updateLead(data.lead_data.sections, this.cookieService.get('LeadId'), sessionKey);
            } else {
                const sessionKey = Guid.create().toString();
                this.cookieService.set('sessionKey', sessionKey);
                this.updateLead(data.lead_data.sections, 0, sessionKey);
            }
            return data;
        }

    }

    

    getRates() {

        const input = {
            'type': 'BestExecutionSearch',
            'id': 'p2ws494x2im6jjoqrkr0',
            'sessionKey': this.cookieService.get('sessionKey'),
            'date': new Date().toUTCString(),
            'message': [
                {
                    'LeadId': this.cookieService.get('LeadId'),
                    'Products': 3
                }
            ]
        }

        /*   const input={
               'type': 'BestExecutionSearch',
               'id': 'p2ws494x2im6jjoqrkr0',
               'sessionKey': 'e4a519f1-d9be-4870-b811-93382bf48654',
               'date': 'Mon, 16 Jul 2018 20:48:47 GMT',
               'message': [		{
                       'LeadId': 85,
                       'Products': 3
                   }
               ]
           }*/

        return this.http.post('https://www.borrowerlynx.com/LynxWebGateway/api/ProductPricing/bestExecutionSearch', input);
    }


    updateLead(data, LeadId, sessionKey) {
        const valid_data = this.validData(data);
        valid_data['LeadId'] = parseInt(LeadId);
        const input = {
            'type': 'LeadAddReq',
            'id': 'p2ws494x2im6jjoqrkr0',
            'sessionKey': sessionKey,
            'date': new Date().toUTCString(),
            'message': [valid_data]
        };

        this.http.post('https://www.borrowerlynx.com/LynxWebGateway/api/Borrower/leadSave', input).subscribe(
            data => {
                this.cookieService.set('sessionKey', sessionKey, new Date(expiry_date), '/');
                this.cookieService.set('LeadId', data['message'][0]['LeadId'], new Date(expiry_date), '/');
                console.log('save cookies');
            }, err => {
                console.log(err);
            }, () => {
                console.log('Step new saved');
            }
        );;

    }


    validData(data) {
        const new_data = {};
        const el = this;
        Object.keys(data).map(function (key) {
            if (key == 'name-full') {
                new_data['FirstName'] = data[key].first_name;
                new_data['LastName'] = data[key].last_name;
                el.cookieService.set('firstName', data[key].first_name, new Date(expiry_date), '/');
                el.cookieService.set('lastName', data[key].last_name, new Date(expiry_date), '/');
            }

            if (key == 'phone') {
                var phone = data[key].match(/\d/g).join('');
                new_data['Phone'] = '+' + phone;
                el.cookieService.set('phone', data[key], new Date(expiry_date), '/');

            }

            if (key == 'email') {
                new_data['Email'] = data[key];
                el.cookieService.set('email', data[key], new Date(expiry_date), '/');
            }

            if (key == 'loan-purpose') {
                let d = 5;
                if (data[key] == 'Purchase') { d = 1; } else if (data[key] == 'Refinance') { d = 2; } else if (data[key] == 'Construction') { d = 3; } else if (data[key] == 'Construction-Perm') { d = 4; }
                new_data['LoanPurpose'] = d;
            }

            if (key == 'under-contract') {
                let d = false;
                if (data[key] == 'Yes') { d = true; }
                new_data['UnderContract'] = d;
            }

            if (key == 'loan-property-value') {
                new_data['LoanPropertyValue'] = data[key];
            }

            if (key == 'contract-payment') {
                new_data['LoanPropertyValue'] = data[key];
            }

            if (key == 'down-payment') {
                new_data['DownPayment'] = data[key];
            }

            if (key == 'monthly-payment') {
                new_data['MonthlyPayment'] = data[key];
            }

            if (key == 'first-home-purchase') {
                let d = false;
                if (data[key] == 'Yes') { d = true; }
                new_data['FirstTimeHomeBuyer'] = d;
            }

            if (key == 'agent') {
                let d = false;
                if (data[key] == 'Yes') { d = true; }
                new_data['Agent'] = d;
            }

            if (key == 'property-usage') {
                let d = 0;
                if (data[key] == 'Primary Home') { d = 1; } else if (data[key] == 'Second Home') { d = 2; } else if (data[key] == 'Rental Property') { d = 3; } else if (data[key] == 'FHA Secondary Residence') { d = 4; }
                new_data['OccupancyType'] = d;
            }

            if (key == 'property-type') {
                let d = 0;
                if (data[key] == 'Single Family') { d = 1; } else if (data[key] == 'Townhome') { d = 2; } else if (data[key] == 'Condo') { d = 3; } else if (data[key] == 'Multi-Family') { d = 4; } else if (data[key] == 'Manafactured Home') { d = 5; } else if (data[key] == 'Co-operative') { d = 6; } else if (data[key] == 'Two-to-Four unit property') { d = 7; } else if (data[key] == 'Commercial') { d = 9; } else if (data[key] == 'Mixed Use - Residential') { d = 10; } else if (data[key] == 'Home and Business') { d = 11; } else if (data[key] == 'Land') { d = 12; }
                new_data['PropertyType'] = d;
            }

            if (key == 'property-city') {
                new_data['PropertyCity'] = data[key];
            }

            if (key == 'credit') {
                let d = 0;
                if (data[key] == 'Excellent') { d = 720; } else if (data[key] == 'Good') { d = 680; } else if (data[key] == 'Fair') { d = 640; } else if (data[key] == 'Poor') { d = 630; }
                new_data['CreditScore'] = d;
            }

            if (key == 'had-fc-ss-b') {
                let d = false;
                if (data[key] == 'Yes') { d = true; }
                new_data['HadFcSsB'] = d;
            }

            if (key == 'qualify-va') {
                let d = false;
                if (data[key] == 'Yes') { d = true; }
                new_data['QualifyVa'] = d;
            }

            if (key == 'self-employed') {
                let d = false;
                if (data[key] == 'Yes') { d = true; }
                new_data['SelfEmployed'] = d;
            }

            if (key == 'loan-timeline-static') {
                new_data['LoanTimelineStatic'] = data[key];
            }

            if (key == 'property-lookup') {
                new_data['SubjectPropertyAddress'] = data[key];
            }

            if (key == 'mortgage-balance') {
                new_data['MortgageBalance'] = data[key];
            }

            if (key == 'ref-purpose') {
                let d = 0;
                if (data[key] == 'No Cash Out') { d = 1; } else if (data[key] == 'Cash-Out/Other') { d = 2; } else if (data[key] == 'Cash-Out/Home Improvement') { d = 3; } else if (data[key] == 'Cash-Out/Debt Consolidation') { d = 4; } else if (data[key] == 'Limited Cash-Out') { d = 5; }
                new_data['RefinancePurpose'] = d;
            }

            if (key == 'refi-loan-amount') {
                new_data['RefinanceLoanAmount'] = data[key];
            }


        });

        return new_data;
    }


}

