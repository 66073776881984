import { Component, OnInit } from '@angular/core';
import { fadeAnimation } from './general/animations';
import { GlobalHttpService } from './http.service';
import { Globals } from './global';
import { HostBinding, HostListener } from '@angular/core';
import * as _ from 'underscore';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AlreadyFilledComponent } from 'src/app/general/already-filled/already-filled.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from 'angular-web-storage';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit {
  title = 'app';

  @HostBinding('class') public cssClass = 'global_style_1';
  constructor(
    public local: LocalStorageService,
    private httpService: GlobalHttpService,
    public globals: Globals,
    private _router: Router,
    private cookieService: CookieService,
    public dialog: MatDialog,
    private titleService: Title,
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver
      .observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait])
      .subscribe(result => {
        if (result.matches) {
          this.globals.activateHandsetLayout();
        }
      });
  }

  ngOnInit() {
    console.log('v2.2');
    this.httpService.getUserIp().subscribe(
      data => {
        this.globals.location = data;
        console.log('Lead added');
      },
      err => {
        console.log(err);
      },
      () => {
        console.log('Step saved');
      }
    );

    this.getLead();
  }

  getParameter() {
    const url_string = window.location.href; //window.location.href
    const url = new URL(url_string);
    const Domain:string = url.searchParams.get("domain");
    return Domain;
}

  getLead() {

    const domain = (undefined == this.getParameter()) ? window.location.hostname : this.getParameter();

    this.httpService.getLead(domain).subscribe(
      data => {
        this.globals.preferences=_.findWhere(data["preferences"],{domain:domain});
        this.globals.lead = data;
        this.local.set('lead', data);
        this.globals.agentId = data['agentId'];
        console.log('i am going to set value');
        this.viewSections();
      },
      err => {
        console.log(err);
        this.globals.lead = this.local.get('lead');
        if (undefined !== this.globals.lead) {
          this.globals.isAllLoad = true;

          this.cssClass =
            'global_style_' + this.globals.lead.preferences[0].style;
        }
      },
      () => {
        this.globals.isAllLoad = true;
        this.cssClass =
          'global_style_' + this.globals.lead.preferences[0].style;
        this.getUserIp();
      }
    );
  }

  viewSections() {
    let el = this;
    const resultModal = this.cookieService.get('access-token');
    const activeSession = this.cookieService.get('activeSession');

    if (this.globals.lead.preferences[0].showChat == true) {
      this.globals.showChat();
    }

    if (
      resultModal.length > 5 &&
      this.globals.lead.preferences[0].allowReturn == true
    ) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '600px';

      this.dialog.open(AlreadyFilledComponent, dialogConfig);
    } else {
      this.globals.startOver();
    }
  }

  getUserIp() {
    this.httpService.getUserIp().subscribe(
      data => {
        this.globals.userGeoPull = data;
      },
      err => {
        this.getUserIp();
      },
      () => {
        console.log('data', this.globals.userGeoPull);
        //              this.globals.isAllLoad = true;
      }
    );
  }

  
  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
}
