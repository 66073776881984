import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global';

@Component({
  selector: 'app-property-type',
  templateUrl: './property-type.component.html',
  styleUrls: ['./property-type.component.scss']
})
export class PropertyTypeComponent implements OnInit {

  constructor(public globals: Globals) { }

  section_modal: string = '';

  ngOnInit() {
    var modal=this.globals.getModalValue();
    if(undefined!=modal){
      this.section_modal=modal;
    }
  
  }

  setValue(value) {
    this.globals.setValue(value);
  }
}
