import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Globals } from 'src/app/global';
import { GlobalHttpService } from 'src/app/http.service';
import { FormGroupDirective, NgForm, Validators} from '@angular/forms';



export interface StateGroup {
  letter: string;
  names: string[];
}

@Component({
  selector: 'app-property-city',
  templateUrl: './property-city.component.html',
  styleUrls: ['./property-city.component.scss']
})
export class PropertyCityComponent implements OnInit {


  userCityState: string;




  myControl: FormControl = new FormControl('', [
    Validators.required
  ]);

  constructor(private _GlobalHttpService: GlobalHttpService, public globals: Globals) { }


  filteredOptions;



  ngOnInit() {
    this.myControl.valueChanges
      .subscribe(val => {
        if (val && typeof val !== 'object') {
          this._GlobalHttpService.searchCities(val).subscribe(results => {
            this.filteredOptions = results;
          });
        }
      });

    const modal = this.globals.getModalValue();
    if (undefined !== modal) {
      this.userCityState = modal;
    } else {
      this.userCityState = this.globals.userGeoPull.city + ', ' + this.globals.userGeoPull.region_name;
    }
  }

  selectAllContent($event) {
    $event.target.select();
  }


  setValue(value) {
    console.log('set value');
    this.globals.setValue(value);
  }

}
