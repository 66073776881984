import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import {Safe} from "./custom.pipe"; // <---

@NgModule({
  declarations:[Safe], // <---
  imports:[CommonModule],
  exports:[Safe] // <---
})

export class SafeHTML{}