import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatDatepickerModule,MatNativeDateModule,MatCheckboxModule} from '@angular/material';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './general/header/header.component';
import { MaterialLibrary } from './material.library';
import {FlexLayoutModule} from '@angular/flex-layout';
import { LoanPurposeComponent } from './sections/loan-purpose/loan-purpose.component';
import { PropertyUsageComponent } from './sections/property-usage/property-usage.component';
import { CreditComponent } from './sections/credit/credit.component';
import { AgentComponent } from './sections/agent/agent.component';
import { AgentDetailsComponent } from './sections/agent-details/agent-details.component';
import { PropertyTypeComponent } from './sections/property-type/property-type.component';
import { SignupComponent } from './general/signup/signup.component';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { GlobalHttpService } from './http.service';   // our custom service, see below

import { Globals } from './global';
import { PropertyCityComponent } from './sections/property-city/property-city.component';
import {FormsModule, FormGroup, FormBuilder, ReactiveFormsModule} from '@angular/forms';
import { AlreadyFilledComponent } from 'src/app/general/already-filled/already-filled.component';
import { IntroOptionsComponent } from './sections/intro-options/intro-options.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FirstHomePurchaseComponent } from './sections/first-home-purchase/first-home-purchase.component';
import { QualifyVaComponent } from './sections/qualify-va/qualify-va.component';
import { LoanAmountEstimateComponent } from './sections/loan-amount-estimate/loan-amount-estimate.component';
import { LoanPropertyValueComponent } from './sections/loan-property-value/loan-property-value.component';
import { SelfEmployedComponent } from './sections/self-employed/self-employed.component';
import { HadFcSsBComponent } from './sections/had-fc-ss-b/had-fc-ss-b.component';
import { NameFullComponent } from './sections/name-full/name-full.component';
import { EmailComponent } from './sections/email/email.component';
import { PhoneComponent } from './sections/phone/phone.component';
import { ThankYouComponent } from './sections/thank-you/thank-you.component';

import { AngularWebStorageModule } from 'angular-web-storage';
import { SplashComponent } from './splash/splash.component';
import { WaitComponent } from './wait/wait.component';
import { DownPaymentComponent } from './sections/down-payment/down-payment.component';
import { MonthlyPaymentComponent } from './sections/monthly-payment/monthly-payment.component';

import { CurrencyMaskModule } from 'ng2-currency-mask';
import { PropertyPricerangeComponent } from './sections/property-pricerange/property-pricerange.component';

import { TextMaskModule } from 'angular2-text-mask';
import { CreditCheckInfoComponent } from './sections/credit-check-info/credit-check-info.component';
import { LoanTimelineComponent } from './sections/loan-timeline/loan-timeline.component';
import { UnderContractComponent } from './sections/under-contract/under-contract.component';
import { ContractPaymentComponent } from './sections/contract-payment/contract-payment.component';
import { PropertyLookupComponent } from './sections/property-lookup/property-lookup.component';
import { MortgageBalanceComponent } from './sections/mortgage-balance/mortgage-balance.component';
import { RefPurposeComponent } from './sections/ref-purpose/ref-purpose.component';
import { RefiLoanAmountComponent } from './sections/refi-loan-amount/refi-loan-amount.component';
import { LoanTimelineStaticComponent } from './sections/loan-timeline-static/loan-timeline-static.component';
import { RatesComponent } from './sections/rates/rates.component';
import { CustomSlideComponent } from './sections/custom-slide/custom-slide.component';

import {SafeHTML} from "./pipes/custom-pipe.module";

@NgModule({
  declarations: [
   
    AppComponent,
    HeaderComponent,
    LoanPurposeComponent,
    PropertyUsageComponent,
    CreditComponent,
    AgentComponent,
    AgentDetailsComponent,
    PropertyTypeComponent,
    SignupComponent,
    PropertyCityComponent,
    AlreadyFilledComponent,
    IntroOptionsComponent,
    FirstHomePurchaseComponent,
    QualifyVaComponent,
    LoanAmountEstimateComponent,
    LoanPropertyValueComponent,
    SelfEmployedComponent,
    HadFcSsBComponent,
    NameFullComponent,
    EmailComponent,
    PhoneComponent,
    ThankYouComponent,
    SplashComponent,
    WaitComponent,
    DownPaymentComponent,
    MonthlyPaymentComponent,
    PropertyPricerangeComponent,
    CreditCheckInfoComponent,
    LoanTimelineComponent,
    UnderContractComponent,
    ContractPaymentComponent,
    PropertyLookupComponent,
    MortgageBalanceComponent,
    RefPurposeComponent,
    RefiLoanAmountComponent,
    LoanTimelineStaticComponent,
    RatesComponent,
    CustomSlideComponent
  ],
  imports: [
    SafeHTML,
    BrowserModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatNativeDateModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialLibrary,
    FlexLayoutModule,
    HttpClientModule,
    TextMaskModule,
    FormsModule,
    CurrencyMaskModule,
    ReactiveFormsModule,
    AngularWebStorageModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [GlobalHttpService, Globals, CookieService],
  bootstrap: [AppComponent],
  entryComponents: [SignupComponent, AlreadyFilledComponent],
  exports:[]
})
export class AppModule { }
