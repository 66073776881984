import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-down-payment',
  templateUrl: './down-payment.component.html',
  styleUrls: ['./down-payment.component.scss']
})
export class DownPaymentComponent implements OnInit {

  
  constructor(public globals: Globals) { }
  old=400000;
  section_modal =  200000;
  section_modal_percentage =  20;
 

  ngOnInit() {
    const modal = this.globals.getModalValue();
    if (undefined !== modal) {
      this.old = this.globals.getEstimateValue();
      this.section_modal = parseFloat(modal);
      this.setPercentage(this.section_modal,'value');
    }else{
      this.old = this.globals.getEstimateValue();
      this.setPercentage(this.section_modal_percentage,'percentage');
    }
   
  }

  setValue(value) {
    this.globals.setValue(value);
  }

  setPercentage(val,type){
    console.log(val,type);
if(type=="percentage"){
  this.section_modal=this.old*(val/100);
}else{
  this.section_modal_percentage=(val/this.old)*100;
}
  }

  loanamountestimate = new FormControl('', [
    Validators.required
  ]);

  loanamountestimate_percentage = new FormControl('', [
    Validators.required
  ]);

}
