import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-contract-payment',
  templateUrl: './contract-payment.component.html',
  styleUrls: ['./contract-payment.component.scss']
})
export class ContractPaymentComponent implements OnInit {

  
  constructor(public globals: Globals) { }

  section_modal = 200000;

  ngOnInit() {
    const modal = this.globals.getModalValue();
    if (undefined !== modal) {
      this.section_modal = parseFloat(modal);
    }
  }

  setValue(value) {
    this.globals.setValue(value);
  }

  contractpayment = new FormControl('', [
    Validators.required
  ]);

}
