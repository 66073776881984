import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SplashComponent } from './splash/splash.component';

import { LoanPurposeComponent } from './sections/loan-purpose/loan-purpose.component';
import { PropertyUsageComponent } from './sections/property-usage/property-usage.component';
import { PropertyTypeComponent } from './sections/property-type/property-type.component';
import { AgentComponent } from './sections/agent/agent.component';
import { PropertyCityComponent } from './sections/property-city/property-city.component';
import { CreditComponent } from './sections/credit/credit.component';
import { IntroOptionsComponent } from './sections/intro-options/intro-options.component';
import { FirstHomePurchaseComponent } from './sections/first-home-purchase/first-home-purchase.component';
import { QualifyVaComponent } from './sections/qualify-va/qualify-va.component';
import { LoanAmountEstimateComponent } from './sections/loan-amount-estimate/loan-amount-estimate.component';
import { LoanPropertyValueComponent } from './sections/loan-property-value/loan-property-value.component';
import { SelfEmployedComponent } from './sections/self-employed/self-employed.component';
import { HadFcSsBComponent } from './sections/had-fc-ss-b/had-fc-ss-b.component';
import { NameFullComponent } from './sections/name-full/name-full.component';
import { EmailComponent } from './sections/email/email.component';
import { PhoneComponent } from './sections/phone/phone.component';
import { ThankYouComponent } from './sections/thank-you/thank-you.component';
import { DownPaymentComponent } from './sections/down-payment/down-payment.component';
import { MonthlyPaymentComponent } from './sections/monthly-payment/monthly-payment.component';
import { PropertyPricerangeComponent } from './sections/property-pricerange/property-pricerange.component';

import { CreditCheckInfoComponent } from './sections/credit-check-info/credit-check-info.component';
import { LoanTimelineComponent } from './sections/loan-timeline/loan-timeline.component';
import { UnderContractComponent } from './sections/under-contract/under-contract.component';
import { ContractPaymentComponent } from './sections/contract-payment/contract-payment.component';
import { PropertyLookupComponent } from './sections/property-lookup/property-lookup.component';
import { MortgageBalanceComponent } from './sections/mortgage-balance/mortgage-balance.component';
import { RefPurposeComponent } from './sections/ref-purpose/ref-purpose.component';
import { RefiLoanAmountComponent } from './sections/refi-loan-amount/refi-loan-amount.component';
import { LoanTimelineStaticComponent } from './sections/loan-timeline-static/loan-timeline-static.component';
import { RatesComponent } from './sections/rates/rates.component';
import { CustomSlideComponent } from './sections/custom-slide/custom-slide.component';




const routes: Routes = [
 // { path: '', redirectTo: 'phone', pathMatch: 'full', data: { state: 'phone' }},
  { path: '', component: SplashComponent, data: { state: '' }},
  { path: 'start', component: SplashComponent, data: { state: '' }},
  { path: 'loan-purpose', component: LoanPurposeComponent, data: { state: 'loan-purpose'} },
  { path: 'property-usage', component: PropertyUsageComponent, data: { state: 'property-usage'} },
  { path: 'property-type', component: PropertyTypeComponent, data: { state: 'property-type'} },
  { path: 'agent', component: AgentComponent, data: { state: 'agent'} },
  { path: 'property-city', component: PropertyCityComponent, data: { state: 'property-city'} },
  { path: 'credit', component: CreditComponent, data: { state: 'credit'} },
  { path: 'intro-options', component: IntroOptionsComponent, data: { state: 'intro-options'} },
  { path: 'first-home-purchase', component: FirstHomePurchaseComponent, data: { state: 'first-home-purchase'} },
  { path: 'qualify-va', component: QualifyVaComponent, data: { state: 'qualify-va'} },
  { path: 'loan-amount-estimate', component: LoanAmountEstimateComponent, data: { state: 'loan-amount-estimate'} },
  { path: 'loan-property-value', component: LoanPropertyValueComponent, data: { state: 'loan-property-value'} },
  { path: 'self-employed', component: SelfEmployedComponent, data: { state: 'self-employed'} },
  { path: 'had-fc-ss-b', component: HadFcSsBComponent, data: { state: 'had-fc-ss-b'} },
  { path: 'name-full', component: NameFullComponent, data: { state: 'name-full'} },
  { path: 'email', component: EmailComponent, data: { state: 'email'} },
  { path: 'phone', component: PhoneComponent, data: { state: 'phone'} },
  { path: 'thank-you', component: ThankYouComponent, data: { state: 'thank-you'} },
  { path: 'monthly-payment', component: MonthlyPaymentComponent, data: { state: 'monthly-payment'} },
  { path: 'down-payment', component: DownPaymentComponent, data: { state: 'down-payment'} },
  { path: 'property-pricerange', component: PropertyPricerangeComponent, data: { state: 'property-pricerange'} },
  { path: 'credit-check-info', component: CreditCheckInfoComponent, data: { state: 'credit-check-info'} },
  { path: 'loan-timeline', component: LoanTimelineComponent, data: { state: 'loan-timeline'} },
  { path: 'under-contract', component: UnderContractComponent, data: { state: 'under-contract'} },
  { path: 'contract-payment', component: ContractPaymentComponent, data: { state: 'contract-payment'} },
  { path: 'property-lookup', component: PropertyLookupComponent, data: { state: 'property-lookup'} },
  { path: 'mortgage-balance', component: MortgageBalanceComponent, data: { state: 'mortgage-balance'} },
  { path: 'ref-purpose', component: RefPurposeComponent, data: { state: 'ref-purpose'} },
  { path: 'refi-loan-amount', component: RefiLoanAmountComponent, data: { state: 'refi-loan-amount'} },
  { path: 'loan-timeline-static', component: LoanTimelineStaticComponent, data: { state: 'loan-timeline-static'} },
  { path: 'rates', component: RatesComponent, data: { state: 'rates'} },
  { path: 'custom-slide', component: CustomSlideComponent, data: { state: 'custom-slide'} },

 // { path: '**', component: NotFound }
];


export const AppRoutingModule = RouterModule.forRoot(routes, {
  useHash: false
});
