import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import * as _ from 'underscore';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';

import { SignupComponent } from '../../general/signup/signup.component';
import { Globals } from '../../global';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  constructor(private route :ActivatedRoute, private router: Router,public globals: Globals,public dialog: MatDialog, private _router: Router,private cookieService:CookieService,private location:Location) {
    
   }

  ngOnInit() {

     }

  previousRoute(){
    console.log("--previous--");
   // const current_section=_.findWhere(this.globals.lead.leadSections,{"sectionComponent": this.cookieService.get("activeSession")});
   // this._router.navigate(['/' + current_section["previous_session"]]);
    this.globals.action="back";
this.location.back();
   
  }

  nextRoute(){
    console.log("--Next--");
    this.globals.action="next";    
    const current_section=_.findWhere(this.globals.lead.leadSections,{"sectionComponent": this.cookieService.get("activeSession")});
    this._router.navigate(['/' + this.globals.getRedirect["previous_session"]]);
   
  }

  reload(){
    window.location.reload();
  }

  openSignup() {

    const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '700px';

       this.dialog.open(SignupComponent, dialogConfig);

  }

}
