
import { Globals } from 'src/app/global';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { GlobalHttpService } from 'src/app/http.service';


@Component({
  selector: 'app-credit-check-info',
  templateUrl: './credit-check-info.component.html',
  styleUrls: ['./credit-check-info.component.scss']
})
export class CreditCheckInfoComponent implements OnInit {

  constructor(
    private _GlobalHttpService: GlobalHttpService, public globals: Globals) { }

    public mask = [ /[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    agree=false;
 
   ssn = '';
   cssn = '';
   dob='';
   adrs='';
   
   social_security_number;
   c_social_security_number;
   date_of_birth;
   address;
 isdisabled=true;

 filteredOptions;
 
   ngOnInit() {
     const modal = this.globals.getModalValue();
     if (undefined !== modal) {

       this.ssn = modal["ssn"];
       this.cssn = modal["cssn"];
       this.dob = modal["dob"];
       this.address = modal["address"];

       this.social_security_number = new FormControl(this.ssn, [
         Validators.required
       ]);
       this.c_social_security_number = new FormControl(this.cssn, [
         Validators.required
       ]);
       
       this.date_of_birth = new FormControl(this.dob, [
        Validators.required
      ]);
      this.address = new FormControl(this.adrs, [
        Validators.required
      ]);
       this.isdisabled=false;
     }else{
       this.social_security_number = new FormControl('', [
         Validators.required
       ]);
       this.c_social_security_number = new FormControl('', [
         Validators.required
       ]);
       this.date_of_birth = new FormControl('', [
        Validators.required
      ]);
      this.address = new FormControl('', [
        Validators.required
      ]);
     }


     this.address.valueChanges
      .subscribe(val => {
        if (val && typeof val !== 'object') {
          this._GlobalHttpService.searchAddress(val).subscribe(results => {
            this.filteredOptions = results;
          });
        }
      });
   }
 
   setValue(ssn,dob,address) {
     console.log("ccc");
     this.globals.setValue({ssn:ssn,dob:dob,address:address});
   }
 

   selectAllContent($event) {
    $event.target.select();
  }
   
 
 }
 