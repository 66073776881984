import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormGroup } from '@angular/forms/src/model';
import { Globals } from 'src/app/global';


@Component({
  selector: 'app-already-filled',
  templateUrl: './already-filled.component.html',
  styleUrls: ['./already-filled.component.scss']
})
export class AlreadyFilledComponent implements OnInit {

  form: FormGroup;




    constructor(public globals: Globals,
      private dialogRef: MatDialogRef<AlreadyFilledComponent>,
      @Inject(MAT_DIALOG_DATA) data) {

  }
      ngOnInit() {

  }
  startOver() {   
    this.globals.startOver();
    this.dialogRef.close();

  }
  resume() {
    this.globals.resume();
    this.dialogRef.close();

  }

}
