import { Component, OnInit } from '@angular/core';
import { Globals } from '../../global';

@Component({
  selector: 'app-intro-options',
  templateUrl: './intro-options.component.html',
  styleUrls: ['./intro-options.component.scss']
})
export class IntroOptionsComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit() {
  }

  setValue(value) {
    this.globals.setValue(value);
  }

}
