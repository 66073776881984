import { Component, OnInit } from '@angular/core';
import { Globals } from '../../global';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit {

  constructor(public globals: Globals) { }

  section_modal = '';

  ngOnInit() {
    const modal = this.globals.getModalValue();
    if (undefined !== modal) {
      this.section_modal = modal;
    }
   
  }

  setValue(value) {
    this.globals.setValue(value);
  }

}
