import { Component, OnInit, ElementRef, Renderer, Pipe } from '@angular/core';
import { Globals } from 'src/app/global';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-custom-slide',
  templateUrl: './custom-slide.component.html',
  styleUrls: ['./custom-slide.component.scss']
})
export class CustomSlideComponent implements OnInit {

  constructor(public globals: Globals, private _router: Router,elementRef: ElementRef, renderer: Renderer) { 
    renderer.listen(elementRef.nativeElement, 'click', (event) => {
      console.log(event);
      for(let a of event["srcElement"].attributes){
if(a.nodeName=="(click)"){
if(a.nodeValue.indexOf("getTo")>-1){
  let rout=a.nodeValue.replace("getTo('","").replace("')","");
  this.gotoRoute(rout);
}
}
      }      

    })
  }

  ngOnInit() {
  }

  gotoRoute(r){
    this._router.navigate(['/' + r]);
  }

}


