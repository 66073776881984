import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global';

@Component({
  selector: 'app-qualify-va',
  templateUrl: './qualify-va.component.html',
  styleUrls: ['./qualify-va.component.scss']
})
export class QualifyVaComponent implements OnInit {

  constructor(public globals: Globals) { }

  section_modal = '';

  ngOnInit() {
    console.log("I am on qualifyva");
    const modal = this.globals.getModalValue();
    if (undefined !== modal) {
      this.section_modal = modal;
    }
  }

  setValue(value) {
    console.log("I set value on qualifyva");
    this.globals.setValue(value);
  }

}
