import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit() {
  }

  reload(){
    this.globals.startOver();
    window.location.reload();
  }

}
