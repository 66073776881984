import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Globals } from 'src/app/global';
import { GlobalHttpService } from 'src/app/http.service';
import { FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatInput } from '@angular/material';



@Component({
  selector: 'app-property-lookup',
  templateUrl: './property-lookup.component.html',
  styleUrls: ['./property-lookup.component.scss']
})
export class PropertyLookupComponent implements OnInit {
  @ViewChild('matInput') nameInput: MatInput;

  userCityState: string;
  unitName: string;



  myControl: FormControl = new FormControl('', [
    Validators.required
  ]);


  constructor(private _GlobalHttpService: GlobalHttpService, public globals: Globals) { }


  filteredOptions;



  ngOnInit() {
    this.myControl.valueChanges
      .subscribe(val => {
        if (val && typeof val !== 'object') {
          this._GlobalHttpService.searchAddress(val).subscribe(results => {
            this.filteredOptions = results;
          });
        }
      });

    const modal = this.globals.getModalValue();
    if (undefined !== modal) {
      this.userCityState = modal;
    } else {
    //  this.userCityState = this.globals.userGeoPull.city + ', ' + this.globals.userGeoPull.region_name;
    }
  }

  selectAllContent($event) {
    $event.target.select();
  }

  setFocus() {
    console.log('clicked');
   // this.nameInput.focus();
  }


  setValue(value) {
    console.log('set value');
    this.globals.setValue(value);
  }

}
