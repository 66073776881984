import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-mortgage-balance',
  templateUrl: './mortgage-balance.component.html',
  styleUrls: ['./mortgage-balance.component.scss']
})
export class MortgageBalanceComponent implements OnInit {


  
  constructor(public globals: Globals) { }

  // section_modal = 600000;
  section_modal = (this.globals.getEstimateValue() * 0.8);


  ngOnInit() {
    const modal = this.globals.getModalValue();
    console.log('LOOOGING ', modal);
    if (undefined !== modal) {
      this.section_modal = parseFloat(modal);
    }
  }

  setValue(value) {
    this.globals.setValue(value);
  }

  loanamountestimate = new FormControl('', [
    Validators.required
  ]);

}
