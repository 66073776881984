import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-refi-loan-amount',
  templateUrl: './refi-loan-amount.component.html',
  styleUrls: ['./refi-loan-amount.component.scss']
})
export class RefiLoanAmountComponent implements OnInit {


  
  constructor(public globals: Globals) { }

  section_modal = this.globals.getEstimateValue();

  ngOnInit() {
    const modal = this.globals.getModalValue();
    if (undefined !== modal) {
      this.section_modal = parseFloat(modal);
    }
  }

  setValue(value) {
    this.globals.setValue(value);
  }

  loanamountestimate = new FormControl('', [
    Validators.required
  ]);

}
