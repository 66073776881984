import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  constructor(public globals: Globals) { }
  email = new FormControl('', [Validators.required, Validators.email]);
  section_modal = '';

  loanamountestimate = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
        this.email.hasError('email') ? 'Not a valid email' :
            '';
  }

  ngOnInit() {
    const modal = this.globals.getModalValue();
    if (undefined !== modal) {
      this.section_modal = modal;
    }
  }

  setValue(value) {
    this.globals.setValue(value);
  }

  isEmail(val) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(val && val !=null){
    return re.test(String(val).toLowerCase());
  }else{
    return false;
  }
    
  }

}
